import { useEffect } from 'react';

import Lenis from 'lenis'

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { Flip } from 'gsap/Flip';

import { preloadImages, isInViewport } from './components/utils';
import { Preview } from './components/Preview';
import Header from './components/Header'
import "./style.css"

gsap.registerPlugin(Flip, ScrollTrigger, ScrollToPlugin);


export default function App() {

  useEffect(()=>{
    const ANIMATION_CONFIG = {duration: 1.5, ease: 'power4.inOut'};

    const previewElems = [...document.querySelectorAll('.preview')];
    const contentElems = [...document.querySelectorAll('.content')];
    const previewItems = [];
    previewElems.forEach((item, pos) => {
      previewItems.push(new Preview(item, contentElems[pos]))
    });
    const backCtrl = document.querySelector('.action--back');
    const about = document.querySelector('.about');
    
    // Smooth scrolling.
    let lenis;
    // Current open item's position
    let currentItem = -1;
    
    let isAnimating = false;
    
    const initSmoothScrolling = () => {
      // Smooth scrolling initialization (using Lenis https://github.com/studio-freight/lenis)
      lenis = new Lenis({
        lerp: 0.1,
        smooth: true,
        direction: 'vertical',
      });
      const scrollFn = (time) => {
        lenis.raf(time);
        requestAnimationFrame(scrollFn);
      };
      requestAnimationFrame(scrollFn);
    };
    
    const animateOnScroll = () => {
    
      for (const item of previewItems) {
    
        gsap.set(item.DOM.imageInner, {transformOrigin: '50% 0%'});
        
        item.scrollTimeline = gsap.timeline({
          scrollTrigger: {
            trigger: item.DOM.el,
            start: 'top bottom',
            end: 'bottom top',
            scrub: true
          }
        })
        .addLabel('start', 0)
        .to(item.DOM.title, {
          ease: 'none',
          yPercent: -100
        }, 'start')
        .to(item.DOM.imageInner, {
          ease: 'none',
          scaleY: 1.8,
        }, 'start')
    
      }
    
    };
    
    const getAdjacentItems = item => {
      let arr = [];
      for (const [position, otherItem] of previewItems.entries()) {
        
        if ( item != otherItem || isInViewport(otherItem.DOM.el) ) {
          arr.push({position: position, item: otherItem});
        }
    
      }
      return arr;
    };
    
    const showContent = item => {
      
      // Get adjacent items. Need to hide them.
      const itemIndex = previewItems.indexOf(item);
      const adjacentItems = getAdjacentItems(item);
      item.adjacentItems = adjacentItems;
    
      const tl = gsap.timeline({
        defaults: ANIMATION_CONFIG,
        onStart: () => {
          gsap.to(window, {duration: 1.5, scrollTo: 0, ease: "power3.inOut"})
          document.querySelector(".projects").classList.add("transparent")
          // Stop the "animate on scroll" timeline for this item
          // item.scrollTimeline.pause();
          // Stop the Lenis instance
          // lenis.stop();
          
          // Overflow hidden and pointer events control class
          document.body.classList.add('content-open');
          // Shows current content element
          item.content.DOM.el.classList.add('content--current');
          gsap.set([item.content.DOM.titleInner, item.content.DOM.metaInner], {
            yPercent: -101,
            opacity: 0
          });
          gsap.set(item.content.DOM.link, {
            yPercent: -101,
            opacity: 0
          });
          gsap.set(item.content.DOM.thumbs, {
            transformOrigin: '0% 0%',
            scale: 0,
            yPercent: 150,
          });
          gsap.set([item.content.DOM.text, backCtrl], {
            opacity: 0
          });
          // Save image current scaleY value
          const scaleY = item.DOM.imageInner.getBoundingClientRect().height / item.DOM.imageInner.offsetHeight;
          item.imageInnerScaleYCached = scaleY;
        },
        onComplete: () => {
          isAnimating = false; 
          if(item.DOM.id == "koa"){
            document.querySelector(".koa").classList.remove("transparent")
          }
          else if(item.DOM.id == "nfs"){
            document.querySelector(".nfs").classList.remove("transparent")
          }
          else if(item.DOM.id == "ilyyw"){
            document.querySelector(".ilyyw").classList.remove("transparent")
          }
        }
      })
      .addLabel('start', 0);
      // hide adjacent preview elements
    
      for (const el of item.adjacentItems) {
        tl.to(el.item.DOM.el, {
          y: el.position < itemIndex ? -window.innerHeight * 10 : window.innerHeight * 10
        }, 'start')
      }
      
      // gsap Flip logic: move the image element inside the content area
        tl.add(() => {
        const flipstate = Flip.getState(item.DOM.image);
        item.content.DOM.el.appendChild(item.DOM.image);
            Flip.from(flipstate, {
                duration: ANIMATION_CONFIG.duration,
                ease: ANIMATION_CONFIG.ease,
                absolute: true,
            });
        }, 'start')
      // preview title
      .to(item.DOM.titleInner, {
        yPercent: 101,
        opacity: 0,
        stagger: -0.03
      }, 'start')
      // Reset image scaleY values (changed during scroll)
      .to(item.DOM.imageInner, {
        scaleY: 1
      }, 'start')
      
      // Content elements come in a bit later
      .addLabel('content', 0.15)
      // Back control button
      .to(backCtrl, {
        opacity: 1
      }, 'content')
      // content link
      .to(item.content.DOM.link, {
        yPercent: 0,
        opacity: 1,
        duration: 2,
      }, 'content')
      // content role
      .to(item.content.DOM.metaInner, {
        yPercent: 0,
        opacity: 1,
        stagger: -0.05
      }, 'content')
      // content title
      .to(item.content.DOM.titleInner, {
        yPercent: 0,
        opacity: 1,
        stagger: -0.05
      }, 'content')
      // content thumbs
      .to(item.content.DOM.thumbs, {
        scale: 1,
        yPercent: 0,
        stagger: -0.05
      }, 'content')
      // content text (lines)
      .add(() => {
            item.content.multiLine.in();
    
        gsap.set(item.content.DOM.text, {
          opacity: 1,
          delay: 0.01
        });
        }, 'content');
    
    };
    
    const hideContent = () => {
      
      // the current open item
      const item = previewItems[currentItem];
    
      gsap.timeline({
        defaults: ANIMATION_CONFIG,
        onStart: () =>{
          if(item.DOM.id == "koa"){
            document.querySelector(".koa").classList.add("transparent")
          }
          else if(item.DOM.id == "nfs"){
            document.querySelector(".nfs").classList.add("transparent")
          }
          else if(item.DOM.id == "ilyyw"){
            document.querySelector(".ilyyw").classList.add("transparent")
          }
        },
        onComplete: () => {
          document.querySelector(".projects").classList.remove("transparent")
          // Stop the "animate on scroll" timeline for this item
          //item.scrollTimeline.play();
    
          // Start the Lenis instance
          lenis.start();
    
          // Overflow hidden and pointer events control class
          document.body.classList.remove('content-open');
          // Hides current content element
          item.content.DOM.el.classList.remove('content--current');
    
          isAnimating = false;
        }
      })
      .addLabel('start', 0)
      // Back control button
      .to(backCtrl, {
        opacity: 0
      }, 'start')
      // content title
      .to(item.content.DOM.titleInner, {
        yPercent: -101,
        opacity: 0,
        stagger: 0.05
      }, 'start')
      .to(item.content.DOM.metaInner, {
        yPercent: -101,
        opacity: 0,
        stagger: 0.05
      }, 'start')
      .to(item.content.DOM.link, {
        yPercent: -101,
        opacity: 0,
        stagger: 0.05
      }, 'start')
      // content thumbs
      .to(item.content.DOM.thumbs, {
        scale: 0,
        yPercent: 150,
        stagger: -0.05
      }, 'start')
      // content text (lines)
      .add(() => {
            item.content.multiLine.out();
        }, 'start')
      
      // Preview elements come in a bit later
      .addLabel('preview', 0.15)
      // hide adjacent preview elements
      .to(item.adjacentItems.map(el => el.item.DOM.el), {
        y: 0
      }, 'preview')
      // gsap Flip logic: move the image element inside the content area
        .add(() => {
        const flipstate = Flip.getState(item.DOM.image);
        item.DOM.imageWrap.appendChild(item.DOM.image);
            Flip.from(flipstate, {
                duration: ANIMATION_CONFIG.duration,
                ease: ANIMATION_CONFIG.ease,
                absolute: true,
            });
        }, 'preview')
      // preview title
      .to(item.DOM.titleInner, {
        yPercent: 0,
        opacity: 1,
        stagger: 0.03
      }, 'preview')
      // Reset image scaleY values
      .to(item.DOM.imageInner, {
        scaleY: item.imageInnerScaleYCached
      }, 'preview')
    
    };
    
    // Initialize the events
    const initEvents = () => {
      for (const [pos,item] of previewItems.entries()) {
        item.DOM.imageWrap.addEventListener('click', () => {
          if(item.DOM.id == "koa"){
            window.history.replaceState(null, "Brandon Pham", "/koalesce")
          }
          else if(item.DOM.id == "nfs"){
            window.history.replaceState(null, "Brandon Pham", "/nfs")
          }
          else if(item.DOM.id == "ilyyw"){
            window.history.replaceState(null, "Brandon Pham", "/ilyyw")
          }
          if ( isAnimating ) return;
          isAnimating = true;
          currentItem = pos;
          showContent(item);
        });
      }
    
      backCtrl.addEventListener('click', () => {
        if ( isAnimating ) return;
        isAnimating = true;
        hideContent();
        window.history.replaceState(null, "Brandon Pham", "/")
      });
    };
    if(window.location.href.indexOf("koalesce") > -1){
      currentItem = 0
      showContent(previewItems[0])
    }
    if(window.location.href.indexOf("nfs") > -1){
      currentItem = 1
      showContent(previewItems[1])
    }
    if(window.location.href.indexOf("ilyyw") > -1){
      currentItem = 2
      showContent(previewItems[2])
    }
    
    // Preload images and initialize scrolling animations
    preloadImages('.preview__img-inner, .content__thumbs-item').then( _ => {
      document.body.classList.remove('loading');
    
      initSmoothScrolling();
      animateOnScroll();
      initEvents();
    });
  })
  
    

    return (
      <>
        <main>
          <Header/>
          <section className="preview-wrap">
            <span className="projects">Projects</span>
            <div className="preview item1">
              <div className="preview__img-wrap" id='koa'><div className="preview__img"><div className="preview__img-inner" style={{backgroundImage: "url(" + "/assets/images/projects/koalesce/featured-koa.png" + ")"}}></div></div></div>
              <div className="preview__title">
                <h2 className="preview__title-main">
                  <span className="oh"><span className="oh__inner">/03</span></span>
                </h2>
              </div>
            </div>
            <div className="preview item2">
              <div className="preview__img-wrap" id='nfs'><div className="preview__img"><div className="preview__img-inner" style={{backgroundImage: "url(" + "/assets/images/projects/nfs/featured-nfs.png" + ")"}}></div></div></div>
              <div className="preview__title">
                <h2 className="preview__title-main">
                  <span className="oh"><span className="oh__inner">/02</span></span>
                </h2>
              </div>
            </div>
            <div className="preview item3">
              <div className="preview__img-wrap" id='ilyyw'><div className="preview__img"><div className="preview__img-inner" style={{backgroundImage: "url(" + "/assets/images/projects/ilyyw/featured-ilyyw-borderless.png" + ")"}}></div></div></div>
              <div className="preview__title">
                <h2 className="preview__title-main">
                  <span className="oh"><span className="oh__inner">/01</span></span>
                </h2>
              </div>
            </div>
          </section>
          <section className="content-wrap">
            <div className="content">
              <div className="content__group">
                <div className="content__title">
                  <span className="oh"><span className="oh__inner">Koalesce</span></span>
                </div>
                <div className="content__meta oh"><span className="oh__inner">Front/Back-end Developer</span></div>
                <div className="content__text">
                  <p>Designed and developed a Shopify headless storefront for the crochet clothing brand, Koalesce.</p>
                  <p>A lot of work went into this project. Aside from the dev, being responsible for the product photography was a new challenge, but was an enjoyable learning process.</p>
                </div>
                <div className="content__link">
                  <a href="https://www.koalesce.xyz/" target="_blank">Visit website</a>
                </div>
              </div>
              
              <div className="content__images koa transparent">
                <img className="content__thumbs-item" src="assets/images/projects/koalesce/koa_home_desktop.png"/>
                <img className="content__thumbs-item" src="assets/images/projects/koalesce/koa_prod_desktop.png"/>
                <img className="content__thumbs-item" src="assets/images/projects/koalesce/koa_prod_page_desktop.png"/>
                <img className="content__thumbs-item" src="assets/images/projects/koalesce/koa_search_desktop.png"/>
                <img className="content__thumbs-item" src="assets/images/projects/koalesce/koa_cart_desktop.png"/>
                <img className="content__thumbs-item" src="assets/images/projects/koalesce/koa_mobile_1.png"/>
                <img className="content__thumbs-item" src="assets/images/projects/koalesce/koa_mobile_2.png"/>
              </div>
            </div>
            <div className="content">
              <div className="content__group">
                <div className="content__title">
                  <span className="oh"><span className="oh__inner">No Future</span></span>
                  <span className="oh"><span className="oh__inner">Studio</span></span>
                </div>
                <div className="content__meta oh"><span className="oh__inner">Front-end Developer</span></div>
                <div className="content__text">
                  <p>Portfolio website for a start-up within the Web3 community, No Future Studio.</p>
                  <p>This was my first project utilizing Three.js and GSAP. Was a very fun introduction to incorporating animations into websites.</p>
                </div>
                <div className="content__link">
                  <a href="https://nofuture.club/" target="_blank">Visit website</a>
                </div>
              </div>
              <div className="content__images nfs transparent">
                <img className="content__thumbs-item" src="assets/images/projects/nfs/nfs_about.png"/>
                <img className="content__thumbs-item" src="assets/images/projects/nfs/nfs_about_char.png"/>
                <img className="content__thumbs-item" src="assets/images/projects/nfs/nfs_contact.png"/>
              </div>
            </div>
            <div className="content">
              <div className="content__group">
                <div className="content__title">
                  <span className="oh"><span className="oh__inner">I Like You,</span></span>
                  <span className="oh"><span className="oh__inner">You're Weird</span></span>
                </div>
                <div className="content__meta oh"><span className="oh__inner">Front/Back-end Developer</span></div>
                <div className="content__text">
                  <p>Collaboration with Mason Rothschild and Amber Park for their Web3 NFT collection, "I Like You, You're Weird".</p>
                  <p>This project required a full website redesign as well as a simulated fighting game in preparation for the release of a second collection, "I Hate You, You're Scary".</p>
                </div>
                <div className="content__link">
                  <a href="https://www.ilyyw.com/" target="_blank">Visit website</a>
                </div>
              </div>
              <div className="content__images ilyyw transparent">
                <img className="content__thumbs-item" src="assets/images/projects/ilyyw/front.png"/>
                <img className="content__thumbs-item" src="assets/images/projects/ilyyw/group1.png"/>
                <img className="content__thumbs-item" src="assets/images/projects/ilyyw/dash_main.png"/>
                <img className="content__thumbs-item" src="assets/images/projects/ilyyw/select_pair1.png"/>
                <img className="content__thumbs-item" src="assets/images/projects/ilyyw/select_pair2.png"/>
                <img className="content__thumbs-item" src="assets/images/projects/ilyyw/stats.png"/>
                <img className="content__thumbs-item" src="assets/images/projects/ilyyw/team_status.png"/>
              </div>
            </div>
            <button className="unbutton action action--back">
              <svg width="25px" height="25px" viewBox="0 0 25 25"><path d="M24 12.001H2.914l5.294-5.295-.707-.707L1 12.501l6.5 6.5.707-.707-5.293-5.293H24v-1z"/></svg>
              <span>Back</span>
            </button>
          </section>
        </main>
      </>
    );
  }