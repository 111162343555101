import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Routes, Route, BrowserRouter} from 'react-router-dom';

import App from "./App";
import About from "./components/About";

const root = createRoot(document.querySelector('#root'))

root.render(
    <BrowserRouter>
        <Routes>
            <Route exact path="/" Component={App}/>
            <Route exact path="/about" Component={About}/>
            <Route exact path="/koalesce" Component={App}/>
            <Route exact path="/nfs" Component={App}/>
            <Route exact path="/ilyyw" Component={App}/>
        </Routes>
    </BrowserRouter>
)