import { Link } from 'react-router-dom';

import Clock from './Clock'

import "../style.css"

export default function Content(){

    return<>
        <header>
            <nav className='d-flex'>
                <div className="button-container ms-2 me-2">
                    <button className=''><Link to={"/about"}>About</Link></button>
                    <span className='user-select-none'>|</span>
                    <a href='ma&#105;lto&#58;br%61n&#100;o%6&#69;p1&#64;%&#55;9%&#54;&#68;%&#54;1%6&#57;l&#46;&#99;&#111;m' className=''>Contact</a>
                </div>
        
                <div className="d-flex align-items-center justify-content-end w-50 ms-2 me-2">
                    <Clock/>
                </div>
            </nav>
        </header>
    </>
}